import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Close as MenuClose } from '@material-ui/icons'
import {
    AdminCheckbox,
    AdminCheckboxContainer,
    AutoCompleteContainer,
    Avatar,
    Dot,
    IconButton,
    ImpersonateRoleMenuContainer,
    Indicator,
    Input,
    LinkBox,
    Logo,
    MarkAllReadLink,
    MenuItem,
    NotificationMenuBox,
    NotificationMenuItem,
    NotificationMenuItemViewAll,
    NotificationMenuPopover,
    NotificationMenuTitle,
    RoleLabelBox,
    RoleValueBox,
    Search,
    SearchIconWrapper,
    SectionLabelBox,
    UserMenuAvatar,
    UserMenuBox,
    UserMenuPopover,
    UtilitiesBox,
    BadgeText,
    HeaderMenu,
    GridMobile,
} from './Header.styled'

import {
    Box,
    Button,
    Divider,
    Drawer,
    Grid,
    Hidden,
    Menu,
    SwipeableDrawer,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setUser as reduxSetUser } from '../redux/actions/UserActions'
import { getUser as reduxGetUser, getDefaultUserCurrency as reduxGetDefaultUserCurrency } from '../redux/selectors/UserSelectors'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppTheme } from '../definitions/Theme'
import { Link, useHistory } from 'react-router-dom'
import { logger } from '../../logger'
import reduxSetSnackbar from '../redux/actions/SnackbarActions'
import { Color } from '@material-ui/lab'
import { homeRoute, invoiceListRoutes, orderListRoute, usersRoute } from '../routes'
import { getUserOrganization as reduxGetUserOrganization } from '../redux/selectors/UserOrganizationSelectors'
import { EventType, UuidV4 } from '@lazr/openapi-client'
import {
    AddressApiService,
    clearImpersonateRequests,
    impersonateRequests,
    localStorageHelper,
    OrderApiService,
    OrganizationApiService,
    UserApiService,
    switchedOrganizationRequests,
} from '../../service/ApiService'
import { Currency, Organization, User } from '../../model'
import { getUserEventNotificationMessageAndLink, getUserRoleName } from '../lib/helpers'
import { setUserOrganization as reduxSetUserOrganization } from '../redux/actions/UserOrganizationActions'
import PaymentReminder from './PaymentReminder'
import {
    setDefaultBillingAddresses as reduxSetDefaultBillingAddresses,
    setDefaultShippingAddresses as reduxSetDefaultShippingAddresses,
} from '../redux/actions/AddressListActions'
import { getOrder as reduxGetOrderInfo } from '../redux/selectors'
import { setOrder as reduxSetOrder } from '../redux/actions'
import SubscriptionService from '../../service/SubscriptionService'
import { UserEventNotification } from '../../model/UserEventNotification'
import { UserEventNotificationApiService } from '@/app/service/ApiService'
import LanguageMenu from '@/app/ui/components/LanguageMenu'
import { useOrganizationList } from './hooks/useOrganizationList'
import { getCurrencyList as reduxGetCurrencyList } from '../redux/selectors/AppStateSelectors'
import { Currency as CurrencyCode, UIAccess } from '@lazr/enums'
import { FilteredAddresses } from '@/app/model/Address'
import { debounce } from 'lodash'
import { UserRole } from '@lazr/enums'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './Header.i18n'

import { MARKETPLACE_ROUTES, NEW_MARKETPLACE_ROUTES } from '@/app/ui/lib/constants'
import { MarketPlaceStep as NewMarketPlaceStep } from '@/app/ui-new/definitions/MarketPlace'
import { MarketPlaceStep } from '@/app/ui/definitions/MarketPlace'
import { FontAwesomeIcon as ButtonIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-common-types'
import { ImpersonateMenu } from '@/app/ui-new/components/Navigation/AppHeader/components/ImpersonateMenu'

import { Grid as GridHeader } from './Header.styled'
import { width } from '@material-ui/system'
import { getMarketPlace as reduxGetNewMarketPlace } from '@/app/ui-new/redux/selectors/MarketPlaceSelectors'
import { initialMarketPlace as newInitialMarketPlace } from '@/app/ui-new/redux/store/InitialState'

interface CurrencyMenuProps {
    user: User | null
}

const CurrencyMenu: React.FunctionComponent<CurrencyMenuProps> = ({ user }) => {
    const { currencyList } = useSelector(reduxGetCurrencyList)
    const dispatch = useDispatch()

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const defaultUserCurrency: CurrencyCode = useSelector(reduxGetDefaultUserCurrency)

    const toggleMenu = (): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const displayMessage = (message: string, severity: Color): void => {
        dispatch(
            reduxSetSnackbar({
                autoHideDuration: 5000,
                message: message,
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: severity,
            }),
        )
    }

    const handleSelectedCurrency = async (currency: Currency): Promise<void> => {
        if (!user) {
            logger.error('Cannot update the display currency preference while user is logged out')

            return
        }

        if (user.currency?.code === currency.code) {
            setIsMenuOpen(false)

            return
        }
        try {
            const updateUserProfile = await UserApiService.editProfile({
                id: user.id,
                organizationId: user.organizationId,
                currency: currency.code,
                uiSettings: user.uiSettings,
            })

            logger.debug(updateUserProfile)
            const currentUser = await UserApiService.getCurrent()
            if (user.getImpersonatingInfo().active) {
                currentUser.impersonate(user.getImpersonatingInfo())
            }
            dispatch(reduxSetUser(currentUser))
        } catch (error: any) {
            logger.error(error)
            displayMessage('An unexpected error occurred while saving your currency preference', 'error')
        }
        setIsMenuOpen(false)
    }

    return (
        <React.Fragment>
            <IconButton
                ref={buttonRef}
                aria-owns={isMenuOpen ? 'menu-appbar' : ''}
                aria-haspopup='true'
                onClick={toggleMenu}
                color='inherit'
            >
                <Typography variant='body1' align='center'>
                    {user?.currency?.code ?? defaultUserCurrency}
                </Typography>
            </IconButton>
            <Menu id='menu-appbar' anchorEl={buttonRef.current} open={isMenuOpen} onClose={(): void => setIsMenuOpen(false)}>
                {currencyList?.map((item: Currency) => (
                    <MenuItem
                        key={item.code}
                        value={item.code}
                        onClick={() => {
                            void (async (): Promise<void> => {
                                await handleSelectedCurrency(item)
                            })()
                        }}
                    >
                        {item.code}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

interface ImpersonateRoleMenuProps {
    user: User
    userOrganization: Organization | null
}

const ImpersonateRoleMenu: React.FunctionComponent<ImpersonateRoleMenuProps> = ({ user, userOrganization }) => {
    const theme = useTheme<AppTheme & Theme>()
    const dispatch = useDispatch()
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [isAdminChecked, setIsAdminChecked] = useState<boolean>(false)
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null)
    const [isImpersonatingRole, setImpersonatingRole] = useState<boolean>(false)
    const orderInfo = useSelector(reduxGetOrderInfo)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const [organizationList] = useOrganizationList(user, userOrganization, isImpersonatingRole)

    const closeMenu = (): void => {
        setIsMenuOpen(false)
    }

    const toggleMenu = (): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const resetRole = async (): Promise<void> => {
        let fetchedDefaultBillingAddresses: FilteredAddresses | null = null
        let fetchedDefaultShippingAddresses: FilteredAddresses | null = null
        localStorageHelper.setImpersonatedRoleInfo({
            organizationId: null,
            organizationName: null,
            isAdmin: false,
        })
        setImpersonatingRole(false)
        clearImpersonateRequests()
        user.resetImpersonation()
        setSelectedOrganization(null)
        dispatch(reduxSetUserOrganization(await OrganizationApiService.getCurrent()))
        dispatch(reduxSetUser(user))
        if (orderInfo) {
            const orderDetails = await OrderApiService.getById(orderInfo.id)
            dispatch(reduxSetOrder(orderDetails))
        }
        ;[fetchedDefaultBillingAddresses, fetchedDefaultShippingAddresses] = await Promise.all([
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                    isBillingDefault: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isShippingDefault: true,
                    isBillingDefault: true,
                },
            ),
        ])
        if (fetchedDefaultBillingAddresses) {
            dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
        }
        if (fetchedDefaultShippingAddresses) {
            dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
        }
    }

    const impersonateRole = async (organization: Organization, isAdminSelected: boolean): Promise<void> => {
        impersonateRequests(organization.id, organization.name, isAdminSelected)
        let fetchedDefaultBillingAddresses: FilteredAddresses | null = null
        let fetchedDefaultShippingAddresses: FilteredAddresses | null = null
        const currentOrganization = await OrganizationApiService.getCurrent()
        if (!currentOrganization) {
            logger.error('impersonateRole: Unable to retrieve current organization')

            return
        }
        ;[fetchedDefaultBillingAddresses, fetchedDefaultShippingAddresses] = await Promise.all([
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                    isBillingDefault: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isShippingDefault: true,
                    isBillingDefault: true,
                },
            ),
        ])
        if (fetchedDefaultBillingAddresses) {
            dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
        }
        if (fetchedDefaultShippingAddresses) {
            dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
        }
        setImpersonatingRole(true)
        setIsAdminChecked(isAdminSelected)

        user.impersonate({
            isAdmin: isAdminSelected,
            organizationId: currentOrganization.id,
            organizationName: currentOrganization.name,
        })
        setSelectedOrganization(currentOrganization)
        dispatch(reduxSetUserOrganization(currentOrganization))
        dispatch(reduxSetUser(user))
    }

    useEffect(() => {
        const impersonatedRole = localStorageHelper.getImpersonatedRoleInfo()
        if (impersonatedRole.organizationId) {
            const impersonatedOrganization = organizationList?.find((organization) => organization.id === impersonatedRole.organizationId)
            setSelectedOrganization(impersonatedOrganization ?? null)
            setImpersonatingRole(true)
            setIsAdminChecked(impersonatedRole.isAdmin)
        }
    }, [organizationList])

    return (
        <React.Fragment>
            <IconButton
                ref={buttonRef}
                aria-owns={isMenuOpen ? 'menu-appbar' : ''}
                aria-haspopup='true'
                onClick={toggleMenu}
                color='inherit'
            >
                <FontAwesomeIcon
                    icon={['fas', 'user-friends']}
                    color={isImpersonatingRole ? theme.palette.warning.main : theme.palette.accent1.contrastText}
                />
            </IconButton>
            <Menu id='menu-ImpersonateRole' anchorEl={buttonRef.current} open={isMenuOpen} onClose={closeMenu}>
                <ImpersonateRoleMenuContainer>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Typography component='h5' variant='h5' align='left' gutterBottom>
                                &#8203;View Application As
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteContainer>
                                <Autocomplete
                                    id='combo-box-ImpersonateRole'
                                    options={organizationList ?? []}
                                    getOptionLabel={(option): string => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    value={selectedOrganization}
                                    loading={!organizationList || !!organizationList.length}
                                    onChange={(_event, value) => {
                                        void (async (): Promise<void> => {
                                            if (value) {
                                                await impersonateRole(value, false)

                                                return
                                            }
                                            await resetRole()
                                        })()
                                    }}
                                    disableClearable={false}
                                    clearOnBlur={true}
                                    renderInput={(params): ReactElement => <TextField {...params} autoFocus label='Organization' />}
                                />
                            </AutoCompleteContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <AdminCheckboxContainer>
                                <FormControlLabel
                                    labelPlacement={'start'}
                                    control={
                                        <AdminCheckbox
                                            checked={isAdminChecked && !!selectedOrganization}
                                            disabled={!selectedOrganization}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                void (async (): Promise<void> => {
                                                    if (selectedOrganization) {
                                                        await impersonateRole(selectedOrganization, e.target.checked)

                                                        return
                                                    }
                                                    setIsAdminChecked(false)
                                                })()
                                            }}
                                            value='checkedAdmin'
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    }
                                    label='Admin privileges'
                                />
                            </AdminCheckboxContainer>
                        </Grid>
                    </Grid>
                </ImpersonateRoleMenuContainer>
            </Menu>
        </React.Fragment>
    )
}

export const UserMenu: React.FunctionComponent<UserMenuProps> = ({ user, userOrganization }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [userOrganizations, setUserOrganizations] = useState<Organization[] | []>([])
    const buttonRef = useRef<HTMLButtonElement>(null)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (user) {
            OrganizationApiService.userOrganizationsByUserId(user.id)
                .then((result) => {
                    setUserOrganizations(result.organizations)
                })
                .catch((error) => console.error(error))
        }
    }, [user])

    const toggleMenu = (/*event: React.MouseEvent<HTMLButtonElement>*/): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const closeMenu = (): void => {
        setIsMenuOpen(false)
    }
    const impersonatingInfo = user?.getImpersonatingInfo()
    const showImpersonationInfo = !!impersonatingInfo && !!userOrganization && (userOrganization.isSystem() || impersonatingInfo.active)

    const getCurrentUser = async (): Promise<void> => {
        const userWithOrganizationPermissions = await UserApiService.getCurrent()
        dispatch(reduxSetUser(userWithOrganizationPermissions))
    }
    const isGmx = !!window.document.URL.endsWith('/gmx')

    const isCoc = !!window.document.URL.endsWith('/commerce-chamber')
    useEffect(()=>{
        if(isCoc){
            localStorage.setItem('create-your-account','/commerce-chamber')
        }else if(isGmx){
            localStorage.setItem('create-your-account','/gmx')
        }
    },[isGmx,isCoc])
    
    return (
        <React.Fragment>
            <IconButton ref={buttonRef} aria-owns={isMenuOpen ? 'menu-appbar' : ''} aria-haspopup='true' onClick={toggleMenu}>
                <Avatar alt={user ? user?.shortFullName || user?.email : 'Guest'} src={user?.pictureUrl ?? ''}>
                    <FontAwesomeIcon icon={['fas', 'user-circle']} />
                </Avatar>
                <Dot />
            </IconButton>
            <UserMenuPopover
                id='menu-appbar'
                anchorEl={buttonRef.current}
                open={isMenuOpen}
                onClose={closeMenu}
                container={buttonRef.current?.parentElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <UserMenuBox p={4}>
                    <Grid container>
                        <Grid item>
                            <Box mr={2}>
                                <UserMenuAvatar alt={user ? user.shortFullName || user.email : 'Guest'} src={user?.pictureUrl ?? ''}>
                                    <FontAwesomeIcon icon={['fas', 'user-circle']} />
                                </UserMenuAvatar>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box pt={1}>
                                <Typography variant='body2'>{user ? user.shortFullName : 'Guest'}</Typography>
                            </Box>
                            <Box pt={1}>
                                <Typography variant='caption'>{user?.email}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={4} />
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Grid container direction='column'>
                                <Grid item>
                                    <RoleLabelBox mt={user && userOrganization?.name ? 2 : 0}>
                                        <Typography variant='body2'>Organization:</Typography>
                                    </RoleLabelBox>
                                </Grid>
                                <Grid item>
                                    <RoleLabelBox>
                                        <Typography variant='body2'>Role:</Typography>
                                    </RoleLabelBox>
                                </Grid>

                                {impersonatingInfo?.active && (
                                    <Grid item>
                                        <RoleLabelBox>
                                            <Typography variant='body2'>Impersonating:</Typography>
                                        </RoleLabelBox>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column'>
                                <Grid item>
                                    {user && userOrganization?.name ? (
                                        <Autocomplete
                                            id='user-organization'
                                            options={userOrganizations}
                                            getOptionLabel={(option): string => option.name}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            value={userOrganization}
                                            loading={false}
                                            onChange={(_event, value) => {
                                                void (async (): Promise<void> => {
                                                    if (value) {
                                                        switchedOrganizationRequests(value.id)
                                                        await getCurrentUser()
                                                        dispatch(reduxSetUserOrganization(value))

                                                        history.push('/orders')
                                                    }
                                                })()
                                            }}
                                            disableClearable={true}
                                            clearOnBlur={true}
                                            disabled={userOrganizations && userOrganizations.length === 1}
                                            style={{ width: 200, marginTop: 9 }}
                                            renderInput={(params): ReactElement => <TextField {...params} autoFocus />}
                                        />
                                    ) : (
                                        <RoleValueBox>
                                            <Typography variant='body2'>{'Guest Organization'}</Typography>
                                        </RoleValueBox>
                                    )}
                                </Grid>
                                <Grid item>
                                    <RoleValueBox>
                                        <Typography variant='body2'>{user ? getUserRoleName(user, userOrganization) : 'User'}</Typography>
                                    </RoleValueBox>
                                </Grid>

                                {impersonatingInfo?.active && (
                                    <Grid item>
                                        <RoleValueBox>
                                            <Typography variant='body2'>
                                                {impersonatingInfo?.organizationName} {impersonatingInfo.role}
                                            </Typography>
                                        </RoleValueBox>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <SectionLabelBox mt={2}>
                        <Typography variant='subtitle2'>My Account</Typography>
                    </SectionLabelBox>
                    <Grid container direction='column'>
                        <Grid item>
                            <LinkBox mt={2}>
                                <Link to={orderListRoute.path}>
                                    <Typography variant='body2'>Orders</Typography>
                                </Link>
                            </LinkBox>
                        </Grid>
                        <Grid item>
                            <LinkBox mt={2}>
                                <Link to={invoiceListRoutes.path}>
                                    <Typography variant='body2'>Invoices</Typography>
                                </Link>
                            </LinkBox>
                        </Grid>
                        <Grid item>
                            <LinkBox mt={2}>
                                <Link to='/account/profile'>
                                    <Typography variant='body2'>Profile</Typography>
                                </Link>
                            </LinkBox>
                        </Grid>
                    </Grid>
                    {showImpersonationInfo && (
                        <React.Fragment>
                            <SectionLabelBox mt={2}>
                                <Typography variant='subtitle2'>My Organization</Typography>
                            </SectionLabelBox>
                            <Grid container direction='column'>
                                <Grid item>
                                    <LinkBox mt={2}>
                                        <Link to={usersRoute.path}>
                                            <Typography variant='body2'>Users</Typography>
                                        </Link>
                                    </LinkBox>
                                </Grid>
                                {/*
                                Add on a modal to show the same as on the users
                                <Grid item>
                                    <LinkBox mt={2}>
                                        <Link to={usersRoute.path}>
                                            <Typography variant='body2'>Invite Members</Typography>
                                        </Link>
                                    </LinkBox>
                                </Grid> */}
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item>
                        <LinkBox mt={2}>
                            {user ? (
                                <Link to='/signout'>
                                    <Typography variant='body2'>Sign out</Typography>
                                </Link>
                            ) : (
                                <Link to={`/create-your-organization${localStorage.getItem('create-your-account') ? localStorage.getItem('create-your-account'): ''}`}>
                                    <Typography variant='body2'>Create your Account</Typography>
                                </Link>
                            )}
                        </LinkBox>
                    </Grid>
                </UserMenuBox>
            </UserMenuPopover>
        </React.Fragment>
    )
}

const NotificationsMenu: React.FunctionComponent = () => {
    const history = useHistory()

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [numUnviewedNotifications, setNumUnviewedNotifications] = useState<number>(0)
    const [notifications, setNotifications] = useState<UserEventNotification[]>([])
    const buttonRef = useRef<HTMLButtonElement>(null)

    const sound = new Audio(`/sounds/notification.mp3?v=${window.lazrVersion}`)

    sound.volume = 0.5

    const toggleMenu = async (): Promise<void> => {
        setIsMenuOpen(!isMenuOpen)
        if (!isMenuOpen) {
            setNumUnviewedNotifications(0)
            try {
                await UserEventNotificationApiService.markAllAsViewed()
            } catch (error: any) {
                logger.error('Unable to mark all user event notifications as viewed')
                logger.error(error.message)
                logger.error(error.stack)
            }
        }
    }

    const closeMenu = (): void => {
        setIsMenuOpen(false)
    }

    const handleNotificationClick = async (id: string, linkTo: string): Promise<void> => {
        setNotifications(
            notifications.map((n: UserEventNotification) => {
                if (n.notificationId === id) {
                    return {
                        ...n,
                        notificationIsRead: true,
                    }
                }

                return n
            }),
        )

        try {
            await UserEventNotificationApiService.markAsRead(id)
        } catch (error: any) {
            logger.error('Unable to mark user event notification as read')
            logger.error(error.message)
            logger.error(error.stack)
        }

        if (linkTo && linkTo !== history.location.pathname) {
            history.push(linkTo)
        } else {
            closeMenu()
        }
    }

    const handleMarkAllAsRead = async (): Promise<void> => {
        setNotifications(
            notifications.map((n: UserEventNotification) => ({
                ...n,
                notificationIsRead: true,
            })),
        )
        try {
            await UserEventNotificationApiService.markAllAsRead()
        } catch (error: any) {
            logger.error('Unable to mark all user event notifications as read')
            logger.error(error.message)
            logger.error(error.stack)
        }
    }

    const debouncedNotifications = React.useRef(
        debounce(async (skipSound: boolean): Promise<void> => {
            const promises: Promise<void>[] = [updateNotifications(skipSound)]
            await Promise.all(promises)
        }, 60000),
    ).current

    const debounceNotifications = useCallback(debouncedNotifications, [])

    const updateNotifications = async (skipSound = false): Promise<void> => {
        try {
            const result = await UserEventNotificationApiService.list({ page: 1, resultPerPage: 10 })
            setNotifications(result.notifications)
            setNumUnviewedNotifications(result.unviewed)
            if (!skipSound) {
                await sound.play().catch((e) => {
                    logger.info(e)
                })
            }
        } catch (error: any) {
            logger.error('Unable to retrieve user event notifications')
            logger.error(error.message)
            logger.error(error.stack)
        }
    }

    useEffect((): (() => void) | undefined => {
        const subscription = SubscriptionService.subscribe(
            [
                EventType.USER_EVENT_NOTIFICATION_ADDED,
                EventType.USER_EVENT_NOTIFICATION_MARKED_AS_READ,
                EventType.USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_READ,
                EventType.USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_VIEWED,
            ],
            async (event): Promise<void> => {
                let skipSound = true
                if (event.type === EventType.USER_EVENT_NOTIFICATION_ADDED) {
                    skipSound = false
                }
                await debounceNotifications(skipSound)
            },
        )

        return subscription.destroy
    }, [])

    useEffect(() => {
        void (async (): Promise<void> => {
            await updateNotifications(true)
        })()
    }, [])

    return (
        <React.Fragment>
            <IconButton
                ref={buttonRef}
                aria-owns={isMenuOpen ? 'menu-appbar' : ''}
                aria-haspopup='true'
                onClick={() => {
                    void (async (): Promise<void> => {
                        await toggleMenu()
                    })()
                }}
                color='inherit'
            >
                <Indicator badgeContent={numUnviewedNotifications} max={99} color='primary' overlap='rectangular'>
                    <FontAwesomeIcon icon={['fas', 'bell']} />
                </Indicator>
            </IconButton>
            <NotificationMenuPopover
                id='menu-appbar'
                anchorEl={buttonRef.current}
                open={isMenuOpen}
                onClose={closeMenu}
                container={buttonRef.current?.parentElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <NotificationMenuBox p={4}>
                    <Grid container direction='column'>
                        <Grid container item direction='row'>
                            <Grid item xs={6}>
                                <NotificationMenuTitle variant='h6'>Notifications</NotificationMenuTitle>
                            </Grid>
                            <Grid container item xs={6} alignItems='center' justifyContent='flex-end'>
                                <Grid item>
                                    {notifications.length > 0 && (
                                        <MarkAllReadLink
                                            onClick={() => {
                                                void (async (): Promise<void> => {
                                                    await handleMarkAllAsRead()
                                                })()
                                            }}
                                        >
                                            Mark all as read
                                        </MarkAllReadLink>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        {notifications.map((n: UserEventNotification) => (
                            <Grid container item key={n.notificationId} xs={12}>
                                {(() => {
                                    const { message, linkTo, timeAgo } = getUserEventNotificationMessageAndLink(n)

                                    return (
                                        <Grid container item>
                                            <NotificationMenuItem
                                                onClick={() => {
                                                    void (async (): Promise<void> => {
                                                        await handleNotificationClick(n.notificationId, linkTo)
                                                    })()
                                                }}
                                            >
                                                <Grid item xs={11}>
                                                    <Typography variant='body1' color='primary'>
                                                        {message}
                                                    </Typography>
                                                    <Typography variant='body2' color='primary'>
                                                        {timeAgo}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='h6' color='primary'>
                                                        {n.notificationIsRead || '•'}
                                                    </Typography>
                                                </Grid>
                                            </NotificationMenuItem>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    )
                                })()}
                            </Grid>
                        ))}
                        <Grid item>
                            <NotificationMenuItemViewAll onClick={(): void => history.push('/notifications')}>
                                <Typography variant='body1' color='primary'>
                                    {' '}
                                    View all notifications
                                </Typography>
                            </NotificationMenuItemViewAll>
                        </Grid>
                    </Grid>
                </NotificationMenuBox>
            </NotificationMenuPopover>
        </React.Fragment>
    )
}

const Header: React.FunctionComponent<Props> = ({ onDrawerToggle }) => {
    const { t } = useI18n(i18n)
    const [mobileOpen, setMobileOpen] = useState<boolean>(false)

    const drawerToggle = (): void => {
        setMobileOpen(!mobileOpen)
    }

    const user = useSelector(reduxGetUser)
    const userOrganization = useSelector(reduxGetUserOrganization)
    const showImpersonationMenu = !!user && !!userOrganization && (userOrganization.isSystem() || user.getImpersonatingInfo().active)
    const isOrganizationAdmin =
        (user?.permissions.organizationRole.role === UserRole.ADMIN || user?.getImpersonatingInfo().isAdmin) ?? false

    const marketplaceUrl = MARKETPLACE_ROUTES[MarketPlaceStep.SHIPMENT_INFORMATION]

     // start temp new marketplace code
     const newMarketplace = useSelector(reduxGetNewMarketPlace)
     const newMarketPlaceStep = localStorage.getItem('newMarketPlaceStep')
 
     const reduxNewLastCompletedStep = newMarketplace.lastCompletedStep
         ? parseInt(String(newMarketplace.lastCompletedStep), 10)
         : newInitialMarketPlace.lastCompletedStep
         
     const localStorageNewMarketplaceStep = newMarketPlaceStep
         ? parseInt(newMarketPlaceStep, 10)
         : newInitialMarketPlace.lastCompletedStep
 
     const newNextStep: NewMarketPlaceStep = Math.max(reduxNewLastCompletedStep, localStorageNewMarketplaceStep)
     const isFinished = newNextStep === NewMarketPlaceStep.THANK_YOU
 
     const reduxNewOrderId = newMarketplace.order?.id ?? ''
     const newOrderId = reduxNewOrderId || localStorage.getItem('newMarketPlaceOrderId')
 
     let newMarketplaceUrl = NEW_MARKETPLACE_ROUTES[NewMarketPlaceStep.LOAD_AND_TERMS]
 
     if (newOrderId && !isFinished) {
         if (
             newMarketplace.isPrepared &&
             NEW_MARKETPLACE_ROUTES[newNextStep] === NEW_MARKETPLACE_ROUTES[NewMarketPlaceStep.LOAD_AND_TERMS]
         ) {
             newMarketplaceUrl = `${NEW_MARKETPLACE_ROUTES[newNextStep]}/?preparedSearchId=${newOrderId}`
         } else {
             newMarketplaceUrl = `${NEW_MARKETPLACE_ROUTES[newNextStep]}/${newOrderId}`
         }
     }
    const navButtons = [
        {
            label: t('System Dashboard'),
            layoutConfig: { width: '154px' },
            icon: 'chart-line',
            section: '/system-dashboard',
            href: '/system-dashboard',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Orders'),
            icon: 'file-alt',
            section: '/orders',
            href: '/orders',
            visible: userOrganization ? [UIAccess.ALL, UIAccess.V1].includes(userOrganization?.uiAccess) : false,
        },
        {
            label: `${t('Orders')}`,
            icon: 'file-alt',
            section: '/beta/orders',
            href: '/beta/orders',
            visible: userOrganization ? [UIAccess.ALL, UIAccess.V2].includes(userOrganization?.uiAccess) : false,
        },
        {
            label: `${t('Schedule Pickup')}`,
            icon: 'calendar',
            section: '/beta/schedule-pickup',
            href: '/beta/schedule-pickup',
            visible: userOrganization ? [UIAccess.ALL, UIAccess.V2].includes(userOrganization?.uiAccess) : false,
        },
        {
            label: t('Marketplace'),
            icon: 'truck-moving',
            section: '/marketplace/shipment-info',
            href: marketplaceUrl,
            visible: userOrganization
                ? [UIAccess.ALL, UIAccess.V1].includes(userOrganization?.uiAccess) &&
                  !(
                      !user?.permissions.performSearch ||
                      (!user?.permissions.allowCollectPaymentTerms && !user?.permissions.allowPrepaidPaymentTerms)
                  )
                : false,
        },
        {
            label: t('Marketplace'),
            icon: 'truck-moving',
            section: '/beta/marketplace',
            href: newMarketplaceUrl,
            visible: userOrganization
                ? [UIAccess.ALL, UIAccess.V2].includes(userOrganization?.uiAccess) &&
                  !(
                      !user?.permissions.performSearch ||
                      (!user?.permissions.allowCollectPaymentTerms && !user?.permissions.allowPrepaidPaymentTerms)
                  )
                : false,
        },
        {
            label: t('Invoices'),
            icon: 'file-invoice-dollar',
            section: '/invoices',
            href: '/invoices',
            visible: user?.permissions.viewCompanyInvoices,
        },
        {
            label: t('Invoice CSV Batches'),
            layoutConfig: { width: '162px' },
            icon: 'receipt',
            section: '/invoice-csv-batches',
            href: '/invoice-csv-batches',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Tracking Updates CSV Batches'),
            icon: 'map-marked-alt',
            section: '/tracking-update-csv-batches',
            layoutConfig: { width: '225px' },
            href: '/tracking-update-csv-batches',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Address Book'),
            icon: 'address-book',
            section: '/addressbook',
            href: '/addressbook',
            visible: user?.permissions.viewCompanyAddressBook,
        },
        {
            label: t('Organizations'),
            icon: 'sitemap',
            section: '/organizations',
            href: '/organizations',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Users'),
            icon: 'users',
            section: '/users',
            href: '/users',
            visible: userOrganization?.isSystem() || isOrganizationAdmin,
        },
        {
            label: t('EDI'),
            icon: 'download',
            section: '/edi',
            href: '/edi',
            visible: !(userOrganization?.isClient() && !userOrganization?.hasEdiSupport),
        },
    ]

    interface hasBadgeNewProps {
        hasNew: boolean
        children: ReactElement
    }
    const HasBadgeNew: React.FunctionComponent<hasBadgeNewProps> = ({ hasNew, children }: hasBadgeNewProps) => {
        if (!hasNew) return children
        return (
            <BadgeText badgeContent={'new'} color='primary' move-right={15} move-top={-3}>
                {children}
            </BadgeText>
        )
    }
    return (
        <>
            <SwipeableDrawer open={mobileOpen} onClose={drawerToggle} onOpen={drawerToggle}>
                <div style={{ width: '60px', paddingTop: '15px' }}>
                    <GridMobile container id='navbar-mobile' justifyContent='center' spacing={3}>
                        <IconButton color='inherit' aria-label='Close drawer' onClick={drawerToggle} id='close-mobile'>
                            <MenuClose />
                        </IconButton>
                        {navButtons.map((b, i) => {
                            if (!b.visible) {
                                return <></>
                            }

                            const isSelected =
                                window.location.pathname.startsWith(b.section) || window.location.pathname.startsWith(`/temp${b.section}`)

                            const showLayoutConfig = isSelected ? b.layoutConfig : null
                            return (
                                <Grid item alignItems='center'>
                                    <HasBadgeNew
                                        key={`navbar-mobile-${i}`}
                                        hasNew={
                                            b.href.includes('/beta/orders') ||
                                            b.href.includes('/beta/marketplace/') ||
                                            b.href.includes('/beta/schedule-pickup')
                                        }
                                    >
                                        <Box className={`navbar-mobile-menu-item ${isSelected ? 'selected' : 'truck-calendar-link'}`}>
                                            <Link to={b.href}>
                                                <Tooltip title={b.label} placement={'bottom'}>
                                                    <>{b.icon && <ButtonIcon icon={['far', b.icon as IconName]} />}</>
                                                </Tooltip>
                                                {/* {isSelected && <Typography variant='body2'>{b.label}</Typography>} */}
                                            </Link>
                                        </Box>
                                    </HasBadgeNew>
                                </Grid>
                            )
                        })}
                    </GridMobile>
                </div>
            </SwipeableDrawer>

            <HeaderMenu>
                <GridHeader container alignItems='center' id='navbar'>
                    <Grid item container xs={3} sm={4} md={3} lg={8} alignItems='center'>
                        <Hidden lgUp>
                            <IconButton color='inherit' aria-label='Open drawer' onClick={drawerToggle}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Link to={homeRoute.path}>
                            <Logo />
                        </Link>
                        <Hidden mdDown>
                            {navButtons.map((b, i) => {
                                if (!b.visible) {
                                    return <></>
                                }

                                const isSelected =
                                    window.location.pathname.startsWith(b.section) ||
                                    window.location.pathname.startsWith(`/temp${b.section}`)

                                const showLayoutConfig = isSelected ? b.layoutConfig : null
                                return (
                                    <HasBadgeNew
                                        key={`navbar-menu-${i}`}
                                        hasNew={
                                            b.href.includes('/beta/orders') ||
                                            b.href.includes('/beta/marketplace/') ||
                                            b.href.includes('/beta/schedule-pickup')
                                        }
                                    >
                                        <Box className={`navbar-menu-item ${isSelected ? 'selected' : ''}`} {...showLayoutConfig}>
                                            <Link to={b.href}>
                                                <Tooltip title={b.label} placement={'bottom'}>
                                                    <>{b.icon && <ButtonIcon icon={['far', b.icon as IconName]} />}</>
                                                </Tooltip>
                                                {isSelected && <Typography variant='body2'>{b.label}</Typography>}
                                            </Link>
                                        </Box>
                                    </HasBadgeNew>
                                )
                            })}
                        </Hidden>
                    </Grid>
                    <Grid item container xs={9} sm={8} md={9} lg={4} justifyContent='flex-end'>
                        {user && (
                            <Box ml={2}>
                                <CurrencyMenu user={user} />
                            </Box>
                        )}
                        {user && (
                            <Box ml={2}>
                                <LanguageMenu user={user} />
                            </Box>
                        )}
                        <UtilitiesBox ml={4}>
                            {!!user && showImpersonationMenu && <ImpersonateRoleMenu user={user} userOrganization={userOrganization} />}

                            <NotificationsMenu />
                            <UserMenu user={user} userOrganization={userOrganization} />
                        </UtilitiesBox>
                    </Grid>
                </GridHeader>
            </HeaderMenu>

            <PaymentReminder />
        </>
    )
}

interface UserMenuProps {
    user: User | null
    userOrganization: Organization | null
}

export interface Props {
    onDrawerToggle: () => void
}

export default Header
